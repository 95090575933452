export const API_URI = "https://sandbox.mma.qwrtx.com/"
export const QWRTX_MMA_GOAT_PLAYERS = 'mma-goat-players'
export const QWRTX_MMA_GOAT_FIGHTERS = 'mma-goat-fighters'
export const QWRTX_MMA_GOAT_EVENTS = 'mma-goat-events'
export const OPTIONS = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
export const FRONT_ADDRESS = "https://sandbox.mma-goat.com"
export const SITE_KEY = "6Lew1RgoAAAAAAbbt2gYXoL3ifLAkaJYseS5bSlF"
export const EXPLORER = "https://sepolia.etherscan.io/"
export const CHAIN_ID = "0xaa36a7"
export const MFIGHTER = "e68ca33e-2b67-4b61-bda4-5cf9892dff96"
export const FFIGHTER = "0549428b-a822-4185-9f36-ff7a2dc5c272"
export const RARITY = {"1": "UNIQUE", "10": "PREMIUM", "100": "RARE", "1000": "COMMON", "unlimited": "STARTER"}
export const STORE = [{"gc": 100, "price": 3.99}, {"gc": 500, "price": 14.99}, {"gc": 5000, "price": 79.99}]